body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/***Header ***/
/* .navbar {
  background-color: #21232B;
} */
.hero-single::before {
  background-color: #21232B;
}

.navbar .nav-item .dropdown-menu .dropdown-item:hover {
  background: #2F84FF;
}